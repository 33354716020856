import { type CurrentUser } from "@wpf-admin-ui/gql/generated/graphql";
import { type NextRouter } from "next/router";

export const allPaths = [
  "/",
  "/projects/[projectId]/entries",
  "/applicants/[userId]",
  "/assessed-applicants",
  "/search",
  "/projects/new",
  "/projects/[projectId]/edit",
  "/projects",
  "/users/[userId]",
  "/users/[userId]/proposal",
  "/dev-projects",
] as const;
type Paths = (typeof allPaths)[number];
type RoleKeys = "isWorksAdminUser" | "isWorksStaffUser" | "isWorksShemateUser";
type GlobalRole = Extract<keyof CurrentUser, RoleKeys>;

const allowedRolesByPath = new Map<Paths, GlobalRole[]>([
  ["/", ["isWorksAdminUser", "isWorksStaffUser"]],
  ["/projects/[projectId]/entries", ["isWorksAdminUser", "isWorksStaffUser"]],
  ["/applicants/[userId]", ["isWorksAdminUser"]],
  ["/assessed-applicants", ["isWorksAdminUser"]],
  ["/search", ["isWorksAdminUser"]],
  ["/projects/new", ["isWorksAdminUser", "isWorksStaffUser"]],
  ["/projects/[projectId]/edit", ["isWorksAdminUser", "isWorksStaffUser", "isWorksShemateUser"]],
  ["/projects", ["isWorksAdminUser", "isWorksStaffUser"]],
  ["/users/[userId]", ["isWorksAdminUser"]],
  ["/users/[userId]/proposal", ["isWorksAdminUser"]],
  ["/dev-projects", ["isWorksAdminUser", "isWorksStaffUser"]],
]);
const authPaths: string[] = ["/auth", "/set_token"];

export const isAllowedPathForCurrentUser = (router: NextRouter, currentUser: CurrentUser | null): boolean => {
  if (!currentUser) {
    return false;
  }
  const allowedRoles = allowedRolesByPath.get(router.pathname as Paths);

  if (!allowedRoles) {
    return false;
  }

  for (const role of allowedRoles) {
    if (currentUser[role]) {
      return true;
    }
  }
  return false;
};

export const isAuthPath = (router: NextRouter): boolean => {
  return authPaths.some((path) => {
    return router.pathname.startsWith(path);
  });
};
